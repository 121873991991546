// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Hero = "H_bX";
export var intro = "H_q";
export var intro__inner = "H_bY";
export var head = "H_k";
export var head__inner = "H_l";
export var register = "H_bZ";
export var register__inner = "H_b0";
export var heading = "H_g";
export var footer = "H_b1";
export var footer__container = "H_b2";
export var scrollDown = "H_bV";
export var aniSpin = "H_c";