// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var ImageComparisonSlider = "r_V";
export var container = "r_f";
export var description = "r_W";
export var handle = "r_X";
export var slider = "r_Y";
export var image = "r_Z";
export var sliderPlaceholder = "r_0";
export var aniSpin = "r_c";