// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var LogoAnimation = "G_bP";
export var sticky = "G_bg";
export var scroll = "G_bQ";
export var scrollProgressBar = "G_bR";
export var scroll__logoDetect = "G_bS";
export var logo = "G_bT";
export var image = "G_Z";
export var scrollDown = "G_bV";
export var scrollDown__button = "G_bW";
export var aniSpin = "G_c";