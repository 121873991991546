// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Feature = "x_bb";
export var divider = "x_bc";
export var inner = "x_3";
export var column = "x_bd";
export var column__inner = "x_bf";
export var sticky = "x_bg";
export var subheading = "x_7";
export var heading = "x_g";
export var description = "x_W";
export var textBlock = "x_bh";
export var link = "x_8";
export var link__inner = "x_bj";
export var image = "x_Z";
export var icon = "x_s";
export var aniSpin = "x_c";