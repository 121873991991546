// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var blogPosts = "C_bv";
export var heading = "C_g";
export var error = "C_d";
export var post = "C_bw";
export var post__heading = "C_bx";
export var post__heading_text = "C_by";
export var post__date = "C_bz";
export var post__header = "C_bB";
export var post__synopsis = "C_bC";
export var post__synopsis__text = "C_bD";
export var post__icon = "C_bF";
export var link = "C_8";
export var aniSpin = "C_c";