// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var container = "D_f";
export var inner = "D_3";
export var heading = "D_g";
export var row = "D_bG";
export var description = "D_W";
export var aniSpin = "D_c";