// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var container = "t_f";
export var inner = "t_3";
export var heading = "t_g";
export var heading__counter = "t_4";
export var heading__text = "t_5";
export var intro = "t_q";
export var aniSpin = "t_c";