// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var container = "v_f";
export var inner = "v_3";
export var header = "v_6";
export var subheading = "v_7";
export var heading = "v_g";
export var description = "v_W";
export var intro = "v_q";
export var link = "v_8";
export var aniSpin = "v_c";