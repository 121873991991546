// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var inner = "F_3";
export var innerGreen = "F_bH";
export var bg = "F_H";
export var text = "F_bJ";
export var image = "F_Z";
export var imageBottom = "F_bK";
export var imageMiddle = "F_bL";
export var heading = "F_g";
export var headingGreen = "F_bp";
export var headingBlack = "F_bM";
export var headingWhite = "F_bN";
export var aniSpin = "F_c";