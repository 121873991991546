// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var borderTop = "B_br";
export var heading = "B_g";
export var head = "B_k";
export var image = "B_Z";
export var intro = "B_q";
export var icon = "B_s";
export var main = "B_bs";
export var bodyText = "B_bt";
export var aniSpin = "B_c";