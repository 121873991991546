// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var pageHeader = "z_bn";
export var heading = "z_g";
export var headingGreen = "z_bp";
export var heading__text = "z_5";
export var icon = "z_s";
export var subheading = "z_7";
export var description = "z_W";
export var description__text = "z_bq";
export var aniSpin = "z_c";